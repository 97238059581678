import { Component, ElementRef, EventEmitter, HostBinding, OnInit, ViewChild, HostListener, Input, Output, Directive  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
import { AlertPopup, config, HttpLoader, path } from 'app/app.config';
import { SocketService } from 'app/socket/socket.service';
import { ELibraryService } from '../../e-library.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileValidationService } from 'app/shared/file-validation.service';
// import { EventEmitter } from 'protractor';
import { AppComponent } from 'app/app.component';
import { ELibraryDetailsComponent } from '../e-library-details.component';
import { ELibraryComponent } from '../../e-library.component';
import { DataSharingService } from 'app/shared/data-sharing.service';
import * as moment from 'moment'
import { ValidationService } from 'app/components/form-validation/validation.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ConfirmationDialogService } from 'app/confirmation-dialog/confirmation-dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
 
export interface QuestionType {
  value: string;
  viewValue: string;
}

// @Directive()
@Component({
  selector: 'app-e-library-upload',
  templateUrl: './e-library-upload.component.html',
  styleUrls: ['./e-library-upload.component.scss']
})
export class ELibraryUploadComponent implements OnInit {

  @Input() classFileList;
  @Input() selectedClassList;
  @Input() maxLength: number = 400;
  @Input() maxHeight = '4rem';
  @Input() isChecked = false;
  @Input() isSelected = false;
  @Output() onElibraryCountUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() getChange = new EventEmitter();

  @ViewChild('scrollframe') scrollFrame: ElementRef;
  @ViewChild('durationInput') durationInput: ElementRef;
  @HostListener('document:click', ['$event'])
  onDocumentClick(event){
    this.show = false;
  }

  clickit($event, ind){
    $event.stopPropagation();
    this.dropdownIndex = ind;
    this.show = !this.show;
  }

  show = false;
  // event:string;
  dropdownIndex:any;

  OFFSET: number = config.DEFAULT_OFFSET;
  RECORD_LIMIT = config.DEFAULT_RECORD_LIMIT;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  activeUrl: string;
  uploadFileForm: FormGroup;
  radioSel:any;
  radioSelected:string;
  radioSelectedString:string;
  isShown=[] ; // hidden by default


  /** Chat File Size limit */
  chatFileSizeLimit: number = config.MAX_ATTACH_FILE_SIZE;
  chatFileExt = config.TOPIC_EXT;


  upLoadedFileList: any[] = [];
  uploadWatermarkLogo: any[] = [];
  fileDuration:string ='';
  selectedRadio = true;
  getElibraryFileList: any[] = [];
  fileUploadingInProgress = false;
  preUploadedFile:any;
  preUploadedWatermarkFile:any;
  preProfileUrl = path.PRE_PROFILE_URL;
  profileUrl = path.PROFILE_URL;
  selectedClass = {};
  selectedOptions:any[] = [];
  selectOption:any;
  private _prevSelected:any;
  isMarkEmpty = false;
  isQuestionEmpty = false;
  myChecked = 0;
  assementError= false;
  priceValidate = false;
  priceErrMsg="";
  discountValidate = false;
  uploadUrl:string='';

  attachedFileSizeLimit: number = config.MAX_ATTACH_FILE_SIZE;
  attachFileExt = config.ATTACHMENT_EXT;

  public subscribers:any = {};
  showPaginationSpinner = false;
  showBtnSpinner = false;
  checkCorrectAns = false;

  /** View Variables */
  domElementControl = {
    showCreateNewQuestions: false,
    showCreateNewSurQuestion: false,
    showCreateNewPolQuestion: false,
    showCreateNewNotesQuestion: false
  };
  fieldArray: Array<any> = [];
  optionArray = [];
  surveyoptionArray = [];
  pollQuestOptionArray = [];
  questionnariesList = [];
  newAttribute:any = {};
  newOption:any = {};
  firstField = true;
  isPublish = false;
  isDownload = false;
  isAllowRetest = false;

  group_id: any="";
  is_question_found = false;
  is_survey_found = false;
  is_polls_found = false;
  is_notes_found = false;
  surveyQuestionEnable = false;
  pollQuestionEnable = false;
  correct = 0;
  selectedOption = [];
  myVideos = [];

  uploadFileIcons = [
    {name: "pdf", icons : '../../../../assets/images/file_types/pdf.png'},
    {name: "doc",icons : '../../../../assets/images/file_types/doc.png'},
    {name: "docx",icons : '../../../../assets/images/file_types/docx.png'},
    {name: "txt",icons : '../../../../assets/images/file_types/txt.png'},
    {name: "ppt",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptx",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptm",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: 'mp3',icons : '../../../../assets/images/file_types/mp3.png'},
    {name: 'mp4',icons : '../../../../assets/images/file_types/mp4.png'},
    {name: 'wav',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'avi',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'png',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'jpeg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'jpg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'html',icons : '../../../../assets/images/file_types/html.png'},
    {name: 'psd',icons : '../../../../assets/images/file_types/psd.png'},
    {name: 'json',icons : '../../../../assets/images/file_types/json.png'},
    {name: 'xlsx',icons : '../../../../assets/images/file_types/xlsx.png'},
    {name: 'xls',icons : '../../../../assets/images/file_types/xls.png'},
    {name: 'css',icons : '../../../../assets/images/file_types/css.png'},
    {name: 'zip',icons : '../../../../assets/images/file_types/zip.png'},
    {name: 'xml',icons : '../../../../assets/images/file_types/xml.png'}
  ];

  RadioOptions = [
    {name: 'Assessment Test', value: 'AT'},
    {name: 'Survey', value: 'S'},
    {name: 'Poll', value: 'P'}
  ];

  file_download = [
    {name: 'Yes', value: true},
    {name: 'No', value: false}
  ];

  publish = [
    {title: 'Yes', value: true},
    {title: 'No', value: false}
  ]

  questions: QuestionType[] = [
    { value: "choice", viewValue: "Choice" },
    { value: "text", viewValue: "Text" },
    // { value: "Rating", viewValue: "Rating" }
  ];

  defaultChecked = 'AT';
  checked = false;
  pub_article = false;
  public selectedField : number = 0
  public selId : string;
  type: any;


  editorConfig: AngularEditorConfig = {
    editable : true,
    spellcheck: true,
    height: 'auto',
    // minHeight: '0',
    maxHeight: '95px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter Description....',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
     ],
     uploadWithCredentials: false,
     sanitize: true,
     toolbarPosition: 'top',
     toolbarHiddenButtons: [
       [
         'undo',
         'redo',
         'strikeThrough',
         'subscript',
         'superscript',
         'heading',
       ],
       [
         'textColor',
         'backgroundColor',
         'customClasses',
         'link',
         'unlink',
         'insertImage',
         'insertVideo',
         'insertHorizontalRule',
         'removeFormat',
       ]
     ]
   };

   editorNotesConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '10rem',
      minHeight: '5rem',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter notes here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
    //   customClasses: [
    //   {
    //     name: 'quote',
    //     class: 'quote',
    //   },
    //   {
    //     name: 'redText',
    //     class: 'redText'
    //   },
    //   {
    //     name: 'titleText',
    //     class: 'titleText',
    //     tag: 'h1',
    //   },
    // ],
    // uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        'heading',
      ],
      [
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
};

 fileLLMformData:FormData=new FormData();


  constructor(
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private router: Router,
    private route: ActivatedRoute,
    private socketService: SocketService,
 
    private elibraryService: ELibraryService,
    private fb: FormBuilder,
    private fileValidationService: FileValidationService,
    private appComponent: AppComponent,
    private elibraryDetailsComponent: ELibraryDetailsComponent,
    private elibraryComponent: ELibraryComponent,
    private dataSharingService: DataSharingService,
    // private validationService: ValidationService
    private confirmDialogService:ConfirmationDialogService,
  ) {
    this.uploadFileForm = fb.group({
      title: ['', Validators.compose([Validators.required, ValidationService.noWhiteSpaceTitleValidator])],
      description: [''],
      duration: [''],
      price: [''],
      discount: [''],
      watermark_title: [''],
      download_link: [''],
      publish: [''],
      assessment: this.fb.array([]),
      survey: this.fb.array([]),
      polls:this.fb.array([]),
      notes: this.fb.array([]),
      min_percentage: [''],
      is_allow_retest:[''],
      random_question:['']
      });

      this.getSelecteditem();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(x => {
      this.group_id = x.get('id');
    });
  }

  chooseUploadFilellm(event){
    const files = event.target.files[0];
    const formData = new FormData();
    formData.append('clientid',localStorage.getItem('company_id'));
    formData.append('file', files,files.name);
    this.elibraryService.uploadLLMPdf(formData).subscribe((res:any)=>{
        this.alert.showAlertMessage(res);
    });
  }

  getSelecteditem(){
    this.radioSel = this.questions.find(Item => Item.value === this.radioSelected);
    this.radioSelectedString = JSON.stringify(this.radioSel);
  }


  openTab(evt, tabName) {

    var i, x, tablinks;
    x = document.getElementsByClassName("content-tab");
    for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tab");
    for (i = 0; i < x.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" is-active", "");
    }
    document.getElementById(tabName).style.display = "flex";
    evt.currentTarget.className += " is-active";
  }

  keyDownHandler(event) {
    if (event.keyCode === 32 && event.target.selectionStart === 0) {
      return false;
    }
  }

  numericOnlyHandler(e){
    if (String.fromCharCode(e.keyCode).match(/[^0-9]/g)){
      this.alert.showAlertMessage({message: 'Please enter number value only'});
      return false;
    }

  }


  assQuestion(): FormArray{
    return this.uploadFileForm.get('assessment') as FormArray;
  }

  assQuestAnswers(questIndex: number): FormArray {
    return this.assQuestion().at(questIndex).get('answer') as FormArray;
  }

  newAssQuestion(): FormGroup{
    return this.fb.group({
      question: [null, Validators.compose([Validators.required, this.noWhitespaceValidator])],
      answer: this.fb.array([]),
      mark: [null, Validators.compose([Validators.required, this.noWhitespaceValidator])],
      answer_type:[null],
      question_type: ['question']
    })
  }

  newAssAnswers(): FormGroup {
    return this.fb.group({
      answer: [null, Validators.compose([Validators.required, this.noWhitespaceValidator])],
      is_correct_answer: [false]
    })
  }

  onCreateAssQuestion(){
    this.domElementControl.showCreateNewQuestions = true;
    this.is_question_found = true;
    let assessmentValue = this.uploadFileForm.get('assessment').value;


    if(assessmentValue.length > 0) {
      let lastIndex = assessmentValue[assessmentValue.length-1];
      if (lastIndex.answer_type==='choice') {
        var checked= false;
        var IsChecked=false;
        for (let i = 0; i < lastIndex.answer.length; i++) {
          const correctAnswer = lastIndex.answer[i];

          if(lastIndex.question!==null && lastIndex.mark!==null && correctAnswer.answer!==null && correctAnswer.is_correct_answer!==false){
            checked = true;
            this.assementError = true;
            this.assQuestion().push(this.newAssQuestion());
            // this.assQuestAnswers(this.assQuestion().length - 1).push(this.newAssAnswers());
            return;
          }
        }

        if(!checked){
          this.assementError = false;
          this.alert.showAlertMessage({message:"Please enter question, answer and select any one answer"});
          return false;
        }
      }else{
        this.assementError = true;
        if(lastIndex.question!==null && lastIndex.answer_type!==null){
          this.assQuestion().push(this.newAssQuestion());
          checked = true;
        }

        if(!checked){
          this.assementError = false;
          this.alert.showAlertMessage({message:"Please enter question, answer and select any one answer"});
          return false;
        }
      }
    }else{
      this.assementError = true;
      this.assQuestion().push(this.newAssQuestion());
      // this.assQuestAnswers(this.assQuestion().length - 1).push(this.newAssAnswers());
    }
  }

  onSelectedType(questions, questIndex){

    let control = <FormArray>this.uploadFileForm.controls.assessment;

    control.value[questIndex]['answer_type'] = questions.value;

    this.isSelected = true;

    if (questions.value=='choice') {
      this.isShown[questIndex] = true;
      this.radioSelectedString = questions.value;
      this.assQuestAnswers(questIndex).push(this.newAssAnswers());
    }else if (questions.value=='text') {
      this.isShown[questIndex] = false;
      control.at(questIndex).get('mark').clearValidators();
      this.assQuestAnswers(questIndex).clear();
    }
  }


  onCreateAssOption(questIndex:number) {
    this.assQuestAnswers(questIndex).push(this.newAssAnswers());
  }

  oncheckedOptions(event, options, questIndex, optionIndex ){

    let control = <FormArray>this.uploadFileForm.controls.assessment;

    let answer = control.value[questIndex].answer;


    for (let index = 0; index < answer.length; index++) {

      if(index===optionIndex){
        answer[index].is_correct_answer = true;
        this.checkCorrectAns = false;
      }
    }

    let removeIdx = this.assQuestAnswers(questIndex).value;

    var checked= false;
    for (let i = 0; i < removeIdx.length; i++) {
      const correctAnswer = removeIdx[i];
      if(correctAnswer.is_correct_answer!==false){
        checked = true;
        this.selectedOptions[questIndex] = false;
        // let button = document.querySelector('.disabledBtn');
        //   button.removeAttribute('disabled');
      }
    }

    let checker = removeIdx => removeIdx.every(v => v.is_correct_answer!== false);

    if(checker && checked){
      // let button = document.querySelector('.disabledBtn');
      //     button.removeAttribute('disabled');
          this.uploadFileForm.markAsDirty();
    }
  }


  removeAssQuestion(questIndex){
    this.assQuestion().removeAt(questIndex);
  }

  removeAssOptions(questIndex, optionIndex){
    this.assQuestAnswers(questIndex).removeAt(optionIndex);

    let removeIdx = this.assQuestAnswers(questIndex).value;

    var checked= false;
    for (let i = 0; i < removeIdx.length; i++) {
      const correctAnswer = removeIdx[i];
      if(correctAnswer.is_correct_answer!==false){
        checked = true;
      }
    }

    if(!checked){
      this.selectedOptions[questIndex] = true;
      this.alert.showAlertMessage({message: 'Please select any one answer'});
      let button = document.querySelector('.disabledBtn');
          button.setAttribute('disabled', 'disabled');
      return false;
    }


  }


  /***Survey create questions */

surveyQuestions(): FormArray {
  return this.uploadFileForm.get('survey') as FormArray;
}

surveyOptions(questIndex:number): FormArray {
  return this.surveyQuestions().at(questIndex).get('answer') as FormArray;
}

newSurveyQuestion(): FormGroup {
  return this.fb.group ({
    question: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
    answer: this.fb.array([]),
    question_type: ['survey']
  })
}

onCreateSurveyQuestion(){
  this.domElementControl.showCreateNewSurQuestion = true;
  this.is_survey_found = true;
  this.surveyQuestions().push(this.newSurveyQuestion());
  this.surveyOptions(this.surveyQuestions().length - 1).push(this.newSurveyOptions());
}

onRemoveSurveyQuestion(questIndex){
  this.surveyQuestions().removeAt(questIndex);
}

newSurveyOptions(): FormGroup {
  return this.fb.group({
    answer: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])]
  })
}

oncreateSurveyOptions(questIndex: number){
  this.surveyOptions(questIndex).push(this.newSurveyOptions());
}

clearFormArray(formArray: FormArray){
 while(formArray.length!==0){
  formArray.removeAt(0);
 }
}


removeSurveyOptions(questIndex, choiceIndex){
  this.surveyOptions(questIndex).removeAt(choiceIndex);

}

/***Poll create questions */
  pollQuestions() : FormArray{
    return this.uploadFileForm.get('polls') as FormArray
  }

  pollChoices(questIndex: number) : FormArray{
    return this.pollQuestions().at(questIndex).get('answer') as FormArray
  }

  newPollQuestion(): FormGroup {
    return this.fb.group({
      question: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      answer: this.fb.array([]),
      question_type: ['polls']
    })
  }

  onCreatePollQuestion(){
    this.domElementControl.showCreateNewPolQuestion = true;
    this.is_polls_found = true;
    this.pollQuestions().push(this.newPollQuestion());
    this.pollChoices(this.pollQuestions().length - 1).push(this.newPollChoice());
  }

  newPollChoice(): FormGroup {
    return this.fb.group({
      answer: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])]
    })
  }

  onCreatePollChoice(questIndex: number) {
    this.pollChoices(questIndex).push(this.newPollChoice());
  }

  removePollQuestion(index){
    this.pollQuestions().removeAt(index);
  }

  removePollChoice(questIndex, choiceIndex){
    this.pollChoices(questIndex).removeAt(choiceIndex);
  }

  enabledSurveyQuestion(){
    this.surveyQuestionEnable =! this.surveyQuestionEnable;
  }

  enablePollQuestions(){
    this.pollQuestionEnable =! this.pollQuestionEnable;
  }


hideFileUploadModal(data){
  this.removeFileFromList(data);
  this.elibraryDetailsComponent.activeFileUpload = false;
  this.elibraryDetailsComponent.activeFileUploadHeader = false;
  this.elibraryDetailsComponent.activeTileView = true;
  this.elibraryComponent.viewLibraryList = false;
  this.uploadFileForm.reset();
  this.preUploadedFile = null;
  this.showBtnSpinner = false;
}


/****notes */
notesQuestions() : FormArray {
  return this.uploadFileForm.get('notes') as FormArray;
}

createNotesQuestion(): FormGroup {
  return this.fb.group({
    question : ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
    question_type: ['notes']
  });
}

onCreateNotesQuestion(){
  this.domElementControl.showCreateNewNotesQuestion = true;
    this.is_notes_found = true;
    this.notesQuestions().push(this.createNotesQuestion());

}

removeNotesQuestion(index){
  this.notesQuestions().removeAt(index);
}

async fileValidationHanlder(files) {
  try {
      const isValidate = await this.fileValidationService.fileValidation(files, this.chatFileExt, this.chatFileSizeLimit);
      return files;
  } catch (err) {
      err.map(li => {
          const errMsg = {
              message: li
          };
          // this.alert.showAlertMessage(errMsg);
          this.alert.showAlertMessage({message: 'The file is too large and cannot be uploaded. Please reduce the size of the file and try again (max 1GB)'});
      });
      return false;
  }
};

async chooseUploadFile(event) {
  const files = event.target.files;
 console.log("files[0].type",files[0].type);
 
if(files[0].type=="application/pdf" || files[0].type.indexOf("application/msword") !=-1 || files[0].type.indexOf("application/vnd.openxmlformats") !=-1){
   this.fileLLMformData = new FormData();
   this.fileLLMformData.append('clientid', localStorage.getItem('company_id'));
   this.fileLLMformData.append('file', files[0],files[0].name);
}
  const vidData = files[0].type.split("/");
  if(vidData[0]==='video' || vidData[0]==='audio'){
    let video = document.createElement(vidData[0]);

    const vid = await this.onloadVideo(files[0], video);

    var sec = vid['duration'];
    var h, m, s, result='';
    // HOURs
    h = Math.floor(sec/3600);
    sec -= h*3600;
    if(h){
      result = h<10 ? '0'+h+':' : h+':';
    }
    // MINUTEs
    m = Math.floor((sec % 3600) / 60);
    sec -= m*60;
    result += m<10 ? '0'+m+':' : m+':';
    // SECONDs
    s=Math.floor((sec % 3600) % 60);
    result += s<10 ? '0'+s : s;
    var videoDuration = result;
    this.uploadFileForm.patchValue({
      duration:videoDuration
    })
  }

    const fileValid = await this.fileValidationHanlder(files);
    if (fileValid) {
      this.concatFileIntoArray(files);
    }
};


onloadVideo(files, video){

   return new Promise((resolve, reject) => {
    try {
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        resolve(this)
      }
      video.src = window.URL.createObjectURL(files);
    } catch (event) {
      reject(event)
    }
  });
}

async chooseWatermarkLogo(event){
const watermark = event.target.files;
const logofileValid = await this.fileValidationHanlder(watermark);
  if(logofileValid){
    this.concatLogoFileIntoArray(watermark);
  }
}



concatFileIntoArray(files) {
  if (files && files.length > 0) {

    if (this.upLoadedFileList.length < 1) {
        const fileObject = {
            user_id: null,
            group_id: null,
            files: []
        };
        const paramId = +this.route.snapshot.paramMap.get('id');
        let fileEl = null;
        let existFileList = null;
        for (let i = 0; i < files.length; i++) {
          const splitArr = files[i].name.split('.');
          files[i].mimeType = splitArr[splitArr.length - 1].toLowerCase();
          files[i].uniqId = Date.now();
          existFileList = [...this.appComponent.selectedFilesList];
          fileEl = existFileList.find(el => el.group_id === paramId);
                if (fileEl) {
                  fileEl.files.unshift(files[i]);
                  this.upLoadedFileList = fileEl.files;

                    if(files[i].mimeType==='pdf' || files[i].mimeType ==='docx' || files[i].mimeType==='doc' || files[i].mimeType==='ppt' || files[i].mimeType ==='pptx' || files[i].mimeType==='txt' || files[i].mimeType==='xml'){
                      this.uploadFileForm.patchValue({
                        duration: ''
                      });
                    }

                } else {
                    fileObject.group_id = paramId;
                    fileObject.files.push(files[i]);
                    this.upLoadedFileList = fileObject.files;
                    existFileList.unshift(fileObject);
                }
        }
        this.appComponent.selectedFilesList = existFileList;
    } else {
        this.alert.showAlertMessage({
            message: 'File seletion limit reached.'
        });
    }
  }
};

concatLogoFileIntoArray(watermark){
  if (watermark && watermark.length > 0) {
    if (this.uploadWatermarkLogo.length < 1) {
        const fileObject = {
            user_id: null,
            group_id: null,
            files: []
        };
        const paramId = +this.route.snapshot.paramMap.get('id');
        let fileEl = null;
        let existFileList = null;
        for (let i = 0; i < watermark.length; i++) {
            const splitArr = watermark[i].name.split('.');
            watermark[i].mimeType = splitArr[splitArr.length - 1].toLowerCase();
            watermark[i].uniqId = Date.now();
            existFileList = [...this.appComponent.selectedFilesList];
                fileEl = existFileList.find(el => el.group_id === paramId);
                if (fileEl) {
                    fileEl.files.unshift(watermark[i]);
                    this.uploadWatermarkLogo = fileEl.files;
                } else {
                    fileObject.group_id = paramId;
                    fileObject.files.push(watermark[i]);
                    this.uploadWatermarkLogo = fileObject.files;
                    existFileList.unshift(fileObject);
                }
        }
        this.appComponent.selectedFilesList = existFileList;
    } else {
        this.alert.showAlertMessage({
            message: 'File seletion limit reached.'
        });
    }
  }
}

/** Remove files from selected list */
removeFileFromList(val) {
  const data = val[0]
  if(this.upLoadedFileList.length > 0) {
    const newFileList = this.upLoadedFileList;
    const currentFiles = newFileList.filter(fl => fl.uniqId !== data.uniqId);
    const paramId = +this.route.snapshot.paramMap.get('id');
    let fileEl = null;
    const existFileList = this.appComponent.selectedFilesList;
    fileEl = existFileList.find(el => el.group_id === paramId);
    if (fileEl) {
      fileEl.files = currentFiles;
    }
    this.upLoadedFileList = currentFiles;
    this.appComponent.selectedFilesList = existFileList;
  }
};

removeFileFromLogoList(val){
  const data = val[0];
  if(this.uploadWatermarkLogo.length > 0){
    const newLogoFileList = this.uploadWatermarkLogo;
    const currentFiles = newLogoFileList.filter(f1 => f1.uniqId !==data.uniqId);
    const paramId = +this.route.snapshot.paramMap.get('id');
    let fileE1 = null;
    const existFileList = this.appComponent.selectedFilesList;
    fileE1 = existFileList.find(el => el.group_id === paramId);
    if(fileE1){
      fileE1.files = currentFiles;
    }
    this.uploadWatermarkLogo = currentFiles;
    this.appComponent.selectedFilesList = existFileList;
  }
}

createNewFileData(formVal){
  console.log("Upload",this.fileLLMformData.append.length);
  console.log("form",formVal);
  if(this.fileLLMformData.append.length > 0){
    this.confirmDialogService.openConfirmDialog('', {
      title:'Confirmation',
      message:'Do you want to training the LLM?',
      btnOkText:'YES',
      btnCancelText:'NO',
      onClose:(data:any)=> {
        if(data){
          this.sendMessageFile(formVal,true);
        }else{
          this.sendMessageFile(formVal,false);
    
        }
    
      }
    })
  }
  // this.sendMessageFile(formVal);
}

removeUploadFile(data){
  try {
    this.removeFileFromList(data)
    // this.upLoadedFileList = [];
    this.preUploadedFile = null;
    this.fileDuration = null;
    this.durationInput.nativeElement.value ='';
  } catch (error) {
    console.log(error);
  }
}

removeWatermarkLogo(data){
  try {
    this.removeFileFromLogoList(data);
    this.preUploadedWatermarkFile = null;
  } catch (error) {
    console.log(error);
  }
}

public noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}

public isNumberKey(evt:any){
  var charCode = (evt.which) ? evt.which : evt.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57)){
    // this.discountValidate = true;
    this.alert.showAlertMessage({message: 'Please enter discount in numbers only. For example 10% or 15%'});
      // this.priceErrMsg = 'Please enter discount number only. For example 535';
      return false;
  }else{
    // this.discountValidate = false;
    return;
  }
}

discountPercentage(evt){
  let discount = evt.target.value;
  if(discount > 100){
    this.alert.showAlertMessage({message: 'Please enter discount min 1% to max 100%'});
    return false
  }else{
    return;
  }
}



public numberOnlyValidator(event :any){

  var numberValue = event.target.value;
  if ((event.which != 46 || numberValue.indexOf('.') != -1)&&(event.which < 48 || event.which > 57)) {
      if((event.which != 46 || numberValue.indexOf('.') != -1)){
        this.alert.showAlertMessage({message: 'Please enter a price in numbers only and two numbers allowed after the decimal point. For example: 523 or 523.36'});
      }
      event.preventDefault();
  }
}


IsDecimalValue(el){
  var ex = /^\s*\d*\.?\d{0,2}\s*$/;

  if(ex.test(el.target.value) === false){
      el.target.value = el.target.value.substring(0,el.target.value.length - 1);
      // this.alert.showAlertMessage({message: 'Price enter two numbers only allowed after decimal point'})
  }
  if(ex.test(el.target.value) === false){
    el.target.value = '';
  }
}

sendMessageFile(data,trainLLM) {
  if (this.upLoadedFileList && this.upLoadedFileList.length > 0 && !this.fileUploadingInProgress) {
    const formFields: any = {
      fieldName: null,
      activity: null,
      to_user_id: null,
      group_id: null,
      message: null,
    };

    this.showBtnSpinner = true;


    if(data.assessment.length > 0){
      this.is_question_found = true;

      let assessmentLength = data.assessment;

      for (let i = 0; i < assessmentLength.length; i++) {
        const el = assessmentLength[i];

        if (el.answer_type==='choice') {
          var checked = false;
          for (let k = 0; k < el.answer.length; k++) {
            const checker = el.answer[k];

            if(el.mark!==null && checker.is_correct_answer!==false){
              checked = true;
            }
          }

          if(!checked){
            this.alert.showAlertMessage({message:"Please check Assessment question answers and select any answer"});
            this.showBtnSpinner = false;
            return false;
          }
        }else{
          if (el.question!==null && el.answer_type!==null) {
            checked = true;
          }

          if(!checked){
            this.alert.showAlertMessage({message:"Please check Assessment question answers and select any answer"});
            this.showBtnSpinner = false;
            return false;
          }
        }
      }

      if(data.random_question ===''){
        this.showBtnSpinner = false;
        this.httpLoader.hideLoader();
        this.alert.showAlertMessage({message: 'Please enter random question number value.'});
        return false;
      }
      else if(data.random_question > assessmentLength.length){
        this.showBtnSpinner = false;
        this.httpLoader.hideLoader();
        this.alert.showAlertMessage({message: 'Please check Random question limit. Based on create assessment question'});
        return false;
      }

    }

    if(data.survey.length > 0){
      this.is_survey_found = true;
    }

    if(data.polls.length > 0) {
      this.is_polls_found = true;
    }

    const userid = parseInt(window.localStorage.getItem('user_id'))

    const classList = this.selectedClassList.filter(x =>  x !== userid )

    const lessonPrice = parseFloat(data.price);
    const lessondiscount = parseInt(data.discount);

    let randomqustNo = parseInt(data.random_question)

    const existFileList = [...this.appComponent.selectedFilesList];
    let fileIndex = null;
    formFields.message = data.title;
    formFields.assessment = JSON.stringify(data.assessment);
    formFields.survey = JSON.stringify(data.survey);
    formFields.polls = JSON.stringify(data.polls);
    formFields.notes = JSON.stringify(data.notes);
    formFields.is_download = data.download_link;
    formFields.price = lessonPrice?lessonPrice : 0;
    formFields.discount = lessondiscount?lessondiscount : 0;
    formFields.water_mark_title = data.watermark_title;
    formFields.description = data.description;
    formFields.duration = data.duration;
    formFields.fieldName = 'group_gallery';
    formFields.activity = 'groupChatMessage';
    formFields.is_question_found = this.is_question_found;
    formFields.is_publish = data.publish;
    formFields.is_survey_found = this.is_survey_found;
    formFields.is_polls_found = this.is_polls_found;
    formFields.is_notes_found = this.is_notes_found;
    formFields.group_id = this.group_id;
    formFields.chat_user_list = classList;
    formFields.min_percentage = data.min_percentage;
    formFields.is_allow_retest = data.is_allow_retest?data.is_allow_retest: false;
    formFields.random_question = randomqustNo?randomqustNo: 0;
    formFields["is_llm_training"] = trainLLM;
    fileIndex = existFileList.findIndex(el => el.group_id === this.group_id);
    if (fileIndex >= 0) {
      existFileList.splice(fileIndex, 1);
    }

    this.appComponent.selectedFilesList = existFileList;
    this.fileUploadingInProgress = true;

    if(trainLLM){
      this.elibraryService.uploadLLMPdf(this.fileLLMformData).subscribe((res:any)=>{
        this.alert.showAlertMessage({"message":"LLM Model Trained Successfully"});
       });
     }
    this.elibraryService.uploadSummaryPdf(this.fileLLMformData).subscribe((res:any)=>{
         
      const extractText = res['result'];
     this.elibraryService.getSummarize(extractText).subscribe((sumRes:any)=>{
      let summarization = sumRes['summarization'];
      formFields['summary'] = summarization;
          this.appComponent.fileUploadWithProgess(formFields, this.upLoadedFileList).then((fileList: any): void => {
          if (this.upLoadedFileList.length === 0) {
            this.fileUploadingInProgress = false;
          }
          const newData = {
            created_at: fileList[0].day,
            download_url: fileList[0].download_file_path,
            file_ext: fileList[0].file_ext,
            file_id: fileList[0].file_id,
            file_path: 'temp',
            file_size: fileList[0].file_size,
            file_type: fileList[0].file_type,
            message: fileList[0].message,
            message_id: fileList[0].message_id,
            message_type: fileList[0].message_type,
            shared_by: fileList[0].first_name,
            thumb_Url: fileList[0].thumb_file_path,
            view_Url: fileList[0].view_Url,
            is_question_found: fileList[0].is_question_found,
            is_survey_found: fileList[0].is_survey_found,
            is_polls_found: fileList[0].is_polls_found,
            is_download: fileList[0].is_download,
            price: fileList[0].price,
            discount: fileList[0].discount,
            duration: fileList[0].duration,
            is_publish: fileList[0].is_publish,
            water_mark_title: fileList[0].water_mark_title,
            is_llm_training:trainLLM,
            summary:summarization
          }
          const copyList = [...this.elibraryDetailsComponent.getElibraryFileList];
          const mergeData = [newData, ...copyList];
          this.elibraryDetailsComponent.getElibraryFileList = mergeData;
          const elibraryCount = {
            count : 1,
            group_id: this.group_id
          };
          this.onElibraryCountUpdate.emit(elibraryCount);
          this.dataSharingService.emitLessonCount(elibraryCount);
          this.httpLoader.hideLoader();
          this.showBtnSpinner = false;
          this.is_question_found = false;
          this.is_survey_found = false;
          this.is_survey_found = false;
          this.uploadFileForm.reset();
          this.elibraryComponent.getElibrary(0);
          this.elibraryDetailsComponent.domElementControl.showCreateElibrary = false;
          this.elibraryDetailsComponent.activeFileUpload = false;
          this.elibraryDetailsComponent.activeTileView = true;
          this.elibraryDetailsComponent.activeFileUploadHeader = false;
          this.elibraryComponent.viewLibraryList = false;
           
          }).catch(error => {
          this.httpLoader.hideLoader();
          this.showBtnSpinner = false;
          this.fileUploadingInProgress = false;
          this.alert.showAlertMessage(error.error);
          this.uploadFileForm.reset();
          this.elibraryComponent.getElibrary(0);
          this.elibraryDetailsComponent.domElementControl.showCreateElibrary = false;
          this.elibraryDetailsComponent.activeFileUpload = false;
          this.elibraryDetailsComponent.activeTileView = true;
          this.elibraryDetailsComponent.activeFileUploadHeader = false;
          this.elibraryComponent.viewLibraryList = false;
        });
     },(error:HttpErrorResponse)=>{
      let summarization = '';
      formFields['summary'] = summarization;
      this.appComponent.fileUploadWithProgess(formFields, this.upLoadedFileList).then((fileList: any): void => {
        if (this.upLoadedFileList.length === 0) {
          this.fileUploadingInProgress = false;
        }
        const newData = {
          created_at: fileList[0].day,
          download_url: fileList[0].download_file_path,
          file_ext: fileList[0].file_ext,
          file_id: fileList[0].file_id,
          file_path: 'temp',
          file_size: fileList[0].file_size,
          file_type: fileList[0].file_type,
          message: fileList[0].message,
          message_id: fileList[0].message_id,
          message_type: fileList[0].message_type,
          shared_by: fileList[0].first_name,
          thumb_Url: fileList[0].thumb_file_path,
          view_Url: fileList[0].view_Url,
          is_question_found: fileList[0].is_question_found,
          is_survey_found: fileList[0].is_survey_found,
          is_polls_found: fileList[0].is_polls_found,
          is_download: fileList[0].is_download,
          price: fileList[0].price,
          discount: fileList[0].discount,
          duration: fileList[0].duration,
          is_publish: fileList[0].is_publish,
          water_mark_title: fileList[0].water_mark_title,
          is_llm_training:trainLLM,
          summary:summarization
        }
        const copyList = [...this.elibraryDetailsComponent.getElibraryFileList];
        const mergeData = [newData, ...copyList];
        this.elibraryDetailsComponent.getElibraryFileList = mergeData;
        const elibraryCount = {
          count : 1,
          group_id: this.group_id
        };
        this.onElibraryCountUpdate.emit(elibraryCount);
        this.dataSharingService.emitLessonCount(elibraryCount);
        this.httpLoader.hideLoader();
        this.showBtnSpinner = false;
        this.is_question_found = false;
        this.is_survey_found = false;
        this.is_survey_found = false;
        this.uploadFileForm.reset();
        this.elibraryComponent.getElibrary(0);
        this.elibraryDetailsComponent.domElementControl.showCreateElibrary = false;
        this.elibraryDetailsComponent.activeFileUpload = false;
        this.elibraryDetailsComponent.activeTileView = true;
        this.elibraryDetailsComponent.activeFileUploadHeader = false;
        this.elibraryComponent.viewLibraryList = false;
         
        }).catch(error => {
        this.httpLoader.hideLoader();
        this.showBtnSpinner = false;
        this.fileUploadingInProgress = false;
        this.alert.showAlertMessage(error.error);
        this.uploadFileForm.reset();
        this.elibraryComponent.getElibrary(0);
        this.elibraryDetailsComponent.domElementControl.showCreateElibrary = false;
        this.elibraryDetailsComponent.activeFileUpload = false;
        this.elibraryDetailsComponent.activeTileView = true;
        this.elibraryDetailsComponent.activeFileUploadHeader = false;
        this.elibraryComponent.viewLibraryList = false;
      });
      // this.httpLoader.hideLoader();
      // this.showBtnSpinner = false;
      // this.fileUploadingInProgress = false;
      // this.alert.showAlertMessage(error.error['message']);
      //   this.uploadFileForm.reset();
      //     this.elibraryComponent.getElibrary(0);
      //     this.elibraryDetailsComponent.domElementControl.showCreateElibrary = false;
      //     this.elibraryDetailsComponent.activeFileUpload = false;
      //     this.elibraryDetailsComponent.activeTileView = true;
      //     this.elibraryDetailsComponent.activeFileUploadHeader = false;
      //     this.elibraryComponent.viewLibraryList = false;
     });  

    });

   
   /* this.appComponent.fileUploadWithProgess(formFields, this.upLoadedFileList).then((fileList: any): void => {
      if (this.upLoadedFileList.length === 0) {
        this.fileUploadingInProgress = false;
      }
      const newData = {
        created_at: fileList[0].day,
        download_url: fileList[0].download_file_path,
        file_ext: fileList[0].file_ext,
        file_id: fileList[0].file_id,
        file_path: 'temp',
        file_size: fileList[0].file_size,
        file_type: fileList[0].file_type,
        message: fileList[0].message,
        message_id: fileList[0].message_id,
        message_type: fileList[0].message_type,
        shared_by: fileList[0].first_name,
        thumb_Url: fileList[0].thumb_file_path,
        view_Url: fileList[0].view_Url,
        is_question_found: fileList[0].is_question_found,
        is_survey_found: fileList[0].is_survey_found,
        is_polls_found: fileList[0].is_polls_found,
        is_download: fileList[0].is_download,
        price: fileList[0].price,
        discount: fileList[0].discount,
        duration: fileList[0].duration,
        is_publish: fileList[0].is_publish,
        water_mark_title: fileList[0].water_mark_title,
        summary:fileList[0].summary,
        is_llm_training:trainLLM
      }
      const copyList = [...this.elibraryDetailsComponent.getElibraryFileList];
      const mergeData = [newData, ...copyList];
      this.elibraryDetailsComponent.getElibraryFileList = mergeData;
      const elibraryCount = {
        count : 1,
        group_id: this.group_id
      };
      this.onElibraryCountUpdate.emit(elibraryCount);
      this.dataSharingService.emitLessonCount(elibraryCount);
      this.httpLoader.hideLoader();
      this.showBtnSpinner = false;
      this.is_question_found = false;
      this.is_survey_found = false;
      this.is_survey_found = false;
      this.uploadFileForm.reset();
      this.elibraryDetailsComponent.domElementControl.showCreateElibrary = false;
      this.elibraryDetailsComponent.activeFileUpload = false;
      this.elibraryDetailsComponent.activeTileView = true;
      this.elibraryDetailsComponent.activeFileUploadHeader = false;
      this.elibraryComponent.viewLibraryList = false;
    }).catch(error => {
      this.httpLoader.hideLoader();
      this.showBtnSpinner = false;
      this.fileUploadingInProgress = false;
      this.alert.showAlertMessage(error.error);
    }); */
  }
};
}
