import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, Inject, NgZone, OnInit, Renderer2, ViewChild  } from '@angular/core';
import { AlertPopup, path } from 'app/app.config';
import { ConfirmationDialogService } from 'app/confirmation-dialog/confirmation-dialog.service';
import { HeaderService } from '../header/header.service';

import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { AssistBotService } from './assist-bot.service';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'app/services/dialog.service';
import { SecureFileService } from 'app/secure-image/secure-image-service';
import { saveAs as importedSaveAs } from "file-saver";
import { DomSanitizer } from '@angular/platform-browser';
declare const $:any;
declare const annyang: any;

// @Directive()
@Component({
  selector: 'app-ai-assist',
  templateUrl: './ai-assist.component.html',
  styleUrls: ['./ai-assist.component.scss'],

})
export class AiAssistComponent implements OnInit {
//  @HostBinding('class.flex-grid') leftBarClass = true;
//  @HostBinding('style.width') flexwidth = '100%';
 
  chatbotData:any=[];
  answerList:any=[];
  chatHistoryList:any=[];
  chatfileList:any=[];
  chatfileAudioList:any=[];
  showSearchSec:boolean=false;
  sortBy:string="time";
  volumeicons:any=["fa fa-volume-off","fa fa-volume-up"];
  microphoneicons:any=["fa fa-microphone-slash","fa fa-microphone"];
  contentWidth: number = 0;
  hideLeftContent:boolean=true;
  hiderightPanel:boolean=false;
  search:any="";
  userDetail:any;
  chatPinList:any=[];
  profileUrl = path.PROFILE_URL;
  suggestionList:any=[];
  searchSuggestion:any;
  autoSearchDafaultValue:string="";
  microphoneActive:boolean=false;
  showSearchesdropdown:boolean=false;
  hidechatContent:boolean=false;

  width:number = window.innerWidth;
  height:number = window.innerHeight;
  mobileWidth:number  = 768;


  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  language:string="eng";
  isShowLanguages:boolean=false;
  dropdownVisible: boolean = false;
  isBelowIpadSize: boolean = window.innerWidth < 768; // Check if screen size is below iPad size initially
  botTemplateName:string="bot";
  botview:boolean;
  showDisclaimer:boolean=true;
  suggestionEnable:boolean=false;

  Bot_template_list:any=[];
  showAudioIndex:any;
  showTabConent:boolean=false;
  hidebotpanel:boolean=false;
  enableTabpanel:string="";
  audioSafeUrl:any;
  @HostListener('window:resize', ['$event'])
  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }
	@HostListener('window:resize', ['$event'])
	onResize(event) {
    this.isBelowIpadSize = window.innerWidth <= 768; // Update screen size on resize
		this.contentWidth = event.target.innerWidth;
    if(this.isBelowIpadSize){
      this.hideLeftContent = true;
      this.hiderightPanel = true;
      // this.document.getElementById("chat-content-wrapper").style.display="flex";
      // this.document.getElementById("right-side-panel").style.width="100%";
    }else{
     // this.hideLeftContent = false;
      this.hiderightPanel = false;
      // this.document.getElementById("right-side-panel").style.width="20%";
      // this.document.getElementById("chat-content-wrapper").style.display="flex";
    }
 
    if(this.isBelowIpadSize){
      this.showDisclaimer = false;
    }else{
     
      this.showDisclaimer = true;
    }
   // this.showDisclaimer = true;
    // const chatheader = this.document.getElementsByClassName('chat-header')[0].clientWidth;
    // this.document.getElementById('chat-list-content').style.width=chatheader+'px';
	}


  voiceActiveSectionDisabled: boolean = true;
	voiceActiveSectionError: boolean = false;
	voiceActiveSectionSuccess: boolean = false;
	voiceActiveSectionListening: boolean = false;
	voiceText: any;
  activeModal:boolean = false;
  selectedReadFile:any;
 @ViewChild('scrollcontent') scrollcontent:ElementRef;
 @ViewChild('toggleButton') toggleButton: ElementRef;

  constructor(private confirmDialogService:ConfirmationDialogService, private alert: AlertPopup,private fb:FormBuilder,private sanitizer: DomSanitizer,
    private headerService: HeaderService,private ngZone: NgZone, @Inject(DOCUMENT) private document: Document,private http:HttpClient,private assistBotService:AssistBotService,
  private renderer:Renderer2,private route:ActivatedRoute,private router:Router,private dialogService:DialogService,private  securefileservice:SecureFileService) {
//     this.renderer.listen('window', 'click',(e:Event)=>{
//       /**
//        * Only run when toggleButton is not clicked
//        * If we don't check this, all clicks (even on the toggle button) gets into this
//        * section which in the result we might never see the menu open!
//        * And the menu itself is checked here, and it's where we check just outside of
//        * the menu and button the condition abbove must close the menu
//        */
//      if(e.target !== this.toggleButton.nativeElement){
//          this.hideLeftContent=true;
//      }
//  });

   }

  ngOnInit(): void {
    let client="absencegpt";
     this.assistBotService.getClientConfig().subscribe((res:any)=>{
              this.assistBotService.GptConfiguration = res.filter((configlist:any)=> configlist['clientModule']==client)[0]['configuration'];
              this.Bot_template_list = this.assistBotService.GptConfiguration['Bot_template'];
     });
    this.chatbotData=[];
    this.botTemplateName="bot";
    this.botview = this.botTemplateName=='bot' ? true :false;
    this.getBotHistory();
    this.getAnswerList().subscribe((result:any)=>{
      this.answerList = result.filter((res:any)=>res['bot_type']=="suggestion");

    });
    this.headerService.getCurrentUserDetail().subscribe((result:any)=>{
      if(result['success']){
        this.userDetail = result['userDetail'];
      }

    });
    this.document.body.classList.add('scroll-stop');
   
     this.route.url.subscribe(() => {
      this.isBelowIpadSize = window.innerWidth < 768;
      if(this.isBelowIpadSize){
        this.hiderightPanel = true;
        this.hideLeftContent = true;
      }
     });

     this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe( (evt:any) => {
          let windowWidth = evt.target.innerWidth;
          if(windowWidth <= this.mobileWidth){
            this.hidechatContent = false;
          
          }else{
            this.hidechatContent = true;
          
          }
      
        });
    // this.changeTemplate(this.botTemplateName);
   
    
    this.assistBotService.historyListBheaviour.subscribe((data:any)=>{
     
      //this.chatHistoryList = [];
      this.chatfileList = [];
      this.chatfileAudioList = [];
      if(data["templateName"]=="bot"){
        this.chatHistoryList = this.assistBotService.chatHistoryList.filter((hList:any)=>hList.question_type=="history" && hList.bot_type=="bot");
        this.chatPinList = this.assistBotService.chatPinList.filter((hList:any)=>hList.question_type=="pin" && hList.bot_type=="bot");
        this.chatbotData=this.chatHistoryList;
        this.chatfileList = [];
        this.chatfileAudioList = [];
        this.chatfileList = this.assistBotService.chatHistoryList.filter((hList:any)=>(hList.question_type=="pdf" || hList.question_type=="word") && hList.bot_type=="bot");
        this.chatfileAudioList = this.assistBotService.chatHistoryList.filter((hList:any)=>hList.question_type=="audio" && hList.bot_type=="bot");
      
      }else if(data["templateName"]=="normal"){
        this.chatHistoryList = this.assistBotService.chatHistoryList.filter((hList:any)=>hList.question_type=="history" && hList.bot_type=="normal");
        this.chatPinList = this.assistBotService.chatPinList.filter((hList:any)=>hList.question_type=="pin" && hList.bot_type=="normal");
        this.chatfileList = [];
        this.chatfileAudioList = [];
        this.chatfileList = this.assistBotService.chatHistoryList.filter((hList:any)=>(hList.question_type=="pdf" || hList.question_type=="word") && hList.bot_type=="normal");
        this.chatfileAudioList = this.assistBotService.chatHistoryList.filter((hList:any)=>hList.question_type=="audio" && hList.bot_type=="normal");
        //this.chatbotData=this.chatHistoryList;
     
      }else if(data["templateName"]=="suggestion"){
       
        this.chatHistoryList = this.assistBotService.chatHistoryList.filter((hList:any)=>hList.question_type=="history" && hList.bot_type=="suggestion");
        this.chatPinList = this.assistBotService.chatPinList.filter((hList:any)=>hList.question_type=="pin" && hList.bot_type=="suggestion");
        this.chatbotData=this.chatHistoryList;
        this.chatfileList = [];
        this.chatfileAudioList = [];
        this.chatfileList = this.assistBotService.chatHistoryList.filter((hList:any)=>(hList.question_type=="pdf" || hList.question_type=="word") && hList.bot_type=="suggestion");
        this.chatfileAudioList = this.assistBotService.chatHistoryList.filter((hList:any)=>hList.question_type=="audio" && hList.bot_type=="suggestion");
       
      }else if(data["templateName"]=="careinsurance"){
        this.chatHistoryList = this.assistBotService.chatHistoryList.filter((hList:any)=>hList.question_type=="history" && hList.bot_type=="careinsurance");
        this.chatPinList = this.assistBotService.chatPinList.filter((hList:any)=>hList.question_type=="pin" && hList.bot_type=="careinsurance");
        this.chatbotData=this.chatHistoryList;
        this.chatfileList = [];
        this.chatfileAudioList = [];
        this.chatfileList = this.assistBotService.chatHistoryList.filter((hList:any)=>(hList.question_type=="pdf" || hList.question_type=="word") && hList.bot_type=="careinsurance");
        this.chatfileAudioList = this.assistBotService.chatHistoryList.filter((hList:any)=>hList.question_type=="audio" && hList.bot_type=="careinsurance");
       }
      // console.log("history list",this.assistBotService.chatHistoryList);
    });
   

    this.hiderightPanel = true;

  }

  SelectedLang(event:any){
       this.language=event.target.value;
       this.getAnswerList().subscribe((result:any)=>{
        this.answerList = result.filter((res:any)=>res['lang']==this.language);
       });
       this.getAnswerList().subscribe((result:any)=>{
        this.suggestionList= result.filter((res:any)=>res['lang']==this.language);
      });


  }

  changeTemplate(value:string){
    this.hideLeftContent = !this.hideLeftContent;
    this.botTemplateName=value;
    this.botview = this.botTemplateName === `${value}` ? true :false;
    this.assistBotService.historyListBheaviour.next({"templateName":value});
    this.suggestionEnable = false;
    if(this.botTemplateName=="suggestion"){
      this.suggestionEnable = true;
      this.getAnswerList().subscribe((result:any)=>{
        this.suggestionList= result.filter((res:any)=>res['bot_type']=='suggestion');
      });
    }else{
      this.suggestionList=[];
    }
    
    if(this.isBelowIpadSize){
      this.hidePanel('rightpanel');
    }
  
  }

  hidePanel(panel:string){
   // this.router.navigateByUrl("/aiassist/choosetemplate");
  this.assistBotService.tabPanelBheaviour.next({'actionShow':false});
   let windowWith = window.innerWidth;
    if(windowWith <= this.mobileWidth){
      if(panel=='hidebotpanel'){
        this.hidebotpanel = true;
        this.hideLeftContent = false;
      }else{
        this.hidebotpanel = false;
        this.hideLeftContent = true;
      }
    } 
   
    

    // setTimeout(() => {
    //   const chatheader = this.document.getElementsByClassName('chat-header')[0].clientWidth;
    //   this.document.getElementById('chat-list-content').style.width=chatheader+'px';
    // },50);
  }
  speakText(state:boolean,textdata:string){

      let text = textdata.replace(/(<([^>]+)>)/gi, "");
      if ('speechSynthesis' in window) {
         var to_speak = new SpeechSynthesisUtterance(text);
         if(state){
          speechSynthesis.cancel();
          window.speechSynthesis.speak(to_speak);
         }else{
          speechSynthesis.cancel();
         }

       } else {
           console.log('not support ');
       }
  }



  copyText(index){
    let text =this.chatbotData[index]['bot_answer'];
    if (!navigator.clipboard){
      // use old commandExec() way
  } else{
    this.alert.showAlertMessage({"message":"Copy this text to clipboard.."});
      navigator.clipboard.writeText(text).then(
          function(){
              console.log("yeah!"); // success


          })
        .catch(
           function() {
            console.log("err"); // error
        });
    }
  }


  resizeIframe(obj){

    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
  }


  sortingBy(event:any){
     if(event=="asc"){
      this.chatHistoryList.sort((a:any,b:any)=>a['question'] < b['question']? 1 : -1);
     }else{
      this.chatHistoryList.sort((a:any,b:any)=>a > b ? 1 : -1);
     }

  }

  setautoComplete(value:any){
    this.autoSearchDafaultValue=value['data'];
      if(value['action'] != "Keypress"){
        this.save();
      }
  }
  save(){
   let questionIndex=0;
    if(this.autoSearchDafaultValue.trim()==""){
      this.alert.showAlertMessage({"message":"Please enter your question"});
      return;
    }
   
   if(this.botTemplateName=="bot"){
    let answerData = [{
      "questionId": '',
      "bot_type": "bot",
      "question": this.autoSearchDafaultValue.trim(),
      "answer": "",
      "search":true
    }];
    this.chatbotData.unshift(answerData[0]);
   }else if(this.botTemplateName=="suggestion"){
      let answer = this.answerList.filter((data:any)=>data['question'].toLowerCase()==this.autoSearchDafaultValue.toLowerCase().trim())
    let answerData = [{
      "questionId": '',
      "bot_type": "suggestion",
      "question": this.autoSearchDafaultValue.trim(),
      "bot_answer":answer[0]['bot_answer'],
      "sequence_id":answer[0]['sequence_id'],
      "search":true
    }];
    this.chatbotData.unshift(answerData[0]);
  
   }else if(this.botTemplateName=="careinsurance"){
    let answerData = [{
      "questionId": '',
      "bot_type": "careinsurance",
      "question": this.autoSearchDafaultValue.trim(),
      "answer": "",
      "search":true
    }];
    this.chatbotData.unshift(answerData[0]);
   }
   
   
    setTimeout(()=>{
    this.autoSearchDafaultValue="";
    },1500);
   

  }





  showSearchbox() {
    this.showSearchSec = !this.showSearchSec;
    this.search="";
  }

  onKeypressEvent(event:any,userForm:any){
    if((event.ctrlKey && event.code=="Enter") || (event.ctrlKey && event.code=="NumpadEnter")){
       this.save();
     }
  }

  gotoHistory(index){
   this.hideLeftContent = !this.hideLeftContent;
    if(this.isBelowIpadSize){
      this.hidePanel('leftpanel');
      this.showDisclaimer = true;
    }
  //  $("#chat-list").animate({
  //     scrollTop:Math.round($('#question'+index).parents('app-bot').offset().top) -  Math.round($("#chat-list").offset().top)
  //   },1000);
   
  //  setTimeout(() => {
  if(this.enableTabpanel=='history'){
    var isScrolling;
    const element = document.getElementById('question'+index);
    element.scrollIntoView({behavior: 'smooth'});
    this.document.getElementById("chat-list-content").addEventListener("scroll",(ev:any)=>{
      // Clear our timeout throughout the scroll
    window.clearTimeout( isScrolling );
    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(function() {
      document.getElementsByTagName("body")[0].scrollTop=0;
    // Run the callback
   
    },100);
    });
  }
      
    
    //  },1000);
      // setTimeout(() => {
      //  document.getElementsByTagName('body')[0].scrollTop=0;
      // },1500);
   }


  removeHistory(history_id){
    this.confirmDialogService.openConfirmDialog('',
    {
      title:'Confirmation',
      message:'Do you want to delete history?',
      btnOkText:'YES',
      btnCancelText:'NO',
      onClose:(data:any)=> {
        if(data){
          this.assistBotService.deleteHistory(history_id).subscribe((result:any)=>{
             if(result['success']){
              this.alert.showAlertMessage({"message":"History was deleted Successfully."});
             }
          });

          let chatbotDataIndex = this.chatbotData.findIndex((d:any)=>d['history_id']==history_id && d.bot_type==this.botTemplateName);
          let chatHistoryDataIndex = this.assistBotService.chatHistoryList.findIndex((d:any)=>d['history_id']==history_id && d.bot_type==this.botTemplateName);
 
          if(chatbotDataIndex !=-1){
            this.chatbotData.splice(chatbotDataIndex,1);
           }

           if(chatHistoryDataIndex !=-1){
             this.assistBotService.chatHistoryList.splice(chatHistoryDataIndex,1);
             this.assistBotService.historyListBheaviour.next({"templateName":this.botTemplateName});
           }
         }
      }});
  }


  removePin(history_id){
    this.confirmDialogService.openConfirmDialog('',
    {
      title:'Confirmation',
      message:'Do you want to delete pin?',
      btnOkText:'YES',
      btnCancelText:'NO',
      onClose:(data:any)=> {
        if(data){
          this.assistBotService.deletePin(history_id).subscribe((result:any)=>{
             if(result['success']){
              this.alert.showAlertMessage({"message":"Pin was deleted successfully."});
             }
          });

          let chatbotDataIndex = this.assistBotService.chatPinList.findIndex((d:any)=>d['history_id']==history_id && d.bot_type==this.botTemplateName);
         
 
          if(chatbotDataIndex !=-1){
            this.assistBotService.chatPinList.splice(chatbotDataIndex,1);
            this.assistBotService.historyListBheaviour.next({"templateName":this.botTemplateName});
           }
         }
      }});
  }

  filterChat(data){
    if(this.search){
      return data.filter((elem:any)=>{
        return  elem['question'].toLowerCase().indexOf(this.search.toLowerCase()) > -1;
       });
    }else{
      return data;
    }

  }

  filterSuggestion(val){

   if(val.target.value !=""){
    this.searchSuggestion=val.target.value;
    this.suggestionList =  this.suggestionList.filter((elem:any)=>{
      return  elem.toLowerCase().indexOf(val.target.value.toLowerCase()) > -1;
     });
   }else{
   this.getAnswerList().subscribe((result:any)=>{
    this.suggestionList = result.map((que:any)=>que['question']);
    });
   }
  }

  autosuggestion(event:any){
    this.suggestionEnable=event.currentTarget.checked;
    if(event.currentTarget.checked){
      this.getAnswerList().subscribe((result:any)=>{
      //  this.suggestionList= result.filter((res:any)=>res['bot_type']=='suggestion');
      });
    }else{
      this.botTemplateName="bot";
      this.suggestionList=[];
    }

  }


  getAnswerList(){
    return this.http.get('../assets/json/ai-chat.json');
  }


  initializeVoiceRecognitionCallback(): void {
		annyang.addCallback('error', (err:any) => {
      console.log("annyang error",err.error);
      // if(err.error==="no-speech"){
      //   this.ngZone.run(() => this.voiceActiveSectionError = true);
			// 	 annyang.abort();
      //    //this.closeVoiceRecognition();
      // }
      if(err.error === 'network'){
        this.voiceText = "Internet is required";
        annyang.abort();
        this.ngZone.run(() => this.voiceActiveSectionSuccess = true);
      } else if (this.voiceText === undefined) {
				this.ngZone.run(() => this.voiceActiveSectionError = true);
				annyang.abort();
			}
		});

		annyang.addCallback('soundstart', (res:any) => {
      console.log("sound start",res);
      this.ngZone.run(() => this.voiceActiveSectionListening = true);
		});

		annyang.addCallback('end', (res:any) => {
      console.log("sound end",res);
      if (this.voiceText === undefined) {
        this.ngZone.run(() => {
          this.voiceActiveSectionError = true;
          this.microphoneActive = false;
          setTimeout(()=>{
            this.closeVoiceRecognition();
          },3000);

         });
				annyang.abort();

			}else{
        this.voiceActiveSectionError = false;
        this.microphoneActive = false;
        this.voiceActiveSectionDisabled = true;
        this.autoSearchDafaultValue = this.voiceText;
        this.save();
        setTimeout(() => {
          this.closeVoiceRecognition();
        },5000);
      }

		});

		annyang.addCallback('result', (userSaid) => {
      if(userSaid.length > 0){
     this.closeVoiceRecognition();
     }
			this.ngZone.run(() => this.voiceActiveSectionError = false);

			let queryText: any = userSaid[0];

			annyang.abort();

      this.voiceText = queryText;
      if(this.voiceText){
        this.voiceActiveSectionListening = false;
        //this.closeVoiceRecognition();
      }
      this.ngZone.run(() => this.voiceActiveSectionListening = false);
      this.ngZone.run(() => this.voiceActiveSectionSuccess = true);

		});
	}

	startVoiceRecognition(): void {
    this.autoSearchDafaultValue="";
    this.voiceActiveSectionDisabled = false;
		this.voiceActiveSectionError = false;
		this.voiceActiveSectionSuccess = false;
    // this.voiceText = undefined;
    this.microphoneActive=true;

		if (annyang) {
     annyang.setLanguage('en');
    // annyang.setLanguage('ar-SA');
			let commands = {
				'demo-annyang': () => { }
			};

			//annyang.addCommands(commands);

      this.initializeVoiceRecognitionCallback();

			annyang.start({ autoRestart: false });
		}
	}
  // toggleDropdown(){
  //   this.isShowLanguages=!this.isShowLanguages
  // }
	closeVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = true;
		this.voiceActiveSectionError = false;
		this.voiceActiveSectionSuccess = false;
		this.voiceActiveSectionListening = false;
		this.voiceText = undefined;

		if(annyang){
      annyang.abort();
    }
	}

  PingChat(index:number){
    let pingfilter = this.chatPinList.filter(ping=>ping['sequence_id']==this.chatbotData[index]["sequence_id"]);
    if(pingfilter.length > 0){
      this.alert.showAlertMessage({"message":"Pin already exist","type":2});
       return;
    }
 
    
    let user_id = window.localStorage.getItem('user_id');
    let botPayload ={
    "user_id":user_id,
    "question":this.chatbotData[index]["question"],
    "answer":this.chatbotData[index]['bot_answer'],
    "question_type":"pin",
    "sequence_id":this.chatbotData[index]["sequence_id"],
    "bot_type":this.botTemplateName
    }
 
   
    if(this.botTemplateName=="suggestion"){
      this.alert.showAlertMessage({"message":"Pin was created successfully"});
      this.assistBotService.chatPinList.unshift(botPayload);
      this.assistBotService.historyListBheaviour.next({"templateName":this.botTemplateName});
    }
    
    this.assistBotService.createHistory(botPayload).subscribe((result:any)=>{
      if(result['success']){
        this.alert.showAlertMessage({"message":"Pin was created successfully"});
        this.assistBotService.chatPinList.unshift(botPayload);
        this.assistBotService.historyListBheaviour.next({"templateName":this.botTemplateName});
      }
    });
}
  

  Like(index:number){
    this.alert.showAlertMessage({"message":"You are like QA"});
  }

  Dislike(index:number){
    this.alert.showAlertMessage({"message":"You are dislike QA"});
  }


  getBotHistory(){
     this.assistBotService.getChatBotHistory().subscribe((result:any)=>{
      console.log("history res",result);
          if(result['success']){
             let data = result['result'];
             this.assistBotService.chatHistoryList=data;
             this.assistBotService.chatPinList=data;
             this.assistBotService.historyListBheaviour.next({"templateName":this.botTemplateName});
          }
     });
  }

  downloadFile(data:any){
    console.log("data",data);
   try {
      const url = data['download_url'];
    
      if (url) {
        this.securefileservice.secureFileDownLoad(url).subscribe(
          (blob) => {       
            importedSaveAs(blob, data['file_id']);
          },
          (error) => {
       console.log(error);
          }
        );
      }
    } catch (error) {
 
      console.log(error);
    }
 
  }

  viewFile(data:any){
    try {
      const url = data['view_url'];
     if (url) {
     
         this.securefileservice.secureReadfile(url).subscribe(
          (blob) => {
            this.activeModal = true;
            this.selectedReadFile =url;
           },
          (error) => {
       
            console.log(error);
          }
        );
      }
    } catch (error) {
 
      console.log(error);
    }
  }

  closeModal(){
    this.activeModal = !this.activeModal;
    this.selectedReadFile="";
  }

  playAudio(data:any) {
    const url = data['view_url'];
    const audiotag = this.document.createElement('audio');
    audiotag.src=url;
    audiotag.play();
    
  }

  getAudioUrl(index:any){
    return this.chatfileAudioList[index]['view_url'];
  }

  showAudio(index:any){
    this.showAudioIndex = index;
    let myBlob = this.chatfileAudioList[index]['view_url'];

    try {
      const url = this.chatfileAudioList[index]['view_url'];
     if (url) {
     
         this.securefileservice.secureReadfile(url).subscribe(
          (blob) => {
            let blobFile = new File([blob], 'myaudio.mp3', {
              type: 'audio/mp3',
            });
           let blobUrl = URL.createObjectURL(blobFile);
            this.audioSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              blobUrl
            );
           },
          (error) => {
       
            console.log(error);
          }
        );
      }
    } catch (error) {
 
      console.log(error);
    }
   
  }

  hideAudio(){
    this.showAudioIndex = null;
    this.audioSafeUrl = '';
  }

  toggleDisclaimer(){
    this.showDisclaimer = !this.showDisclaimer;
  }

  closeTabPanel(event:any){
    console.log("event",event);
    this.hideLeftContent = event['action'];
  }
  ngOnDestroy(): void {
    this.document.body.classList.remove('scroll-stop');
    
  }

  ShowTabpanel(panelName:any){
    this.hideLeftContent = false;
    this.enableTabpanel=panelName;
  }
 

}
