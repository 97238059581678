import { AlertPopup } from './../../app.config';
import { Component, OnInit, Directive  } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { config } from '../../app.config';
import { ForgotPasswordService } from './forgot.password.service';
import { ValidationService } from '../form-validation/validation.service';
import { DataSharingService } from 'app/shared/data-sharing.service';


// @Directive()
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: []
})

export class ForgotPasswordComponent implements OnInit {
  loginForm: FormGroup;
  showMailConfirmation = false;
  copyRights:number = config.copyrights;
  forgotRegMailId:any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private forgotService: ForgotPasswordService,
    private dataSharingService: DataSharingService,
    private alert: AlertPopup) {

    this.loginForm = formBuilder.group({
      // email_id: ['baskar@test.com', Validators.required]
      email_id: ['', Validators.compose([Validators.required, ValidationService.emailValidator])]
    });
  }

  ngOnInit() {
    // this.dataSharingService.getForgotMailId().subscribe(
    //   response => {
    //     this.forgotRegMailId = response;
    //     // this.loginForm.get('email_id').value(response);
    //     this.loginForm.patchValue({
    //       email_id: this.forgotRegMailId
    //     })
    //   }
    // )

    this.forgotRegMailId = window.sessionStorage.getItem('email_id');

    this.loginForm.patchValue({
          email_id: this.forgotRegMailId
        })
   };

  returnToLogin() {
    this.router.navigate(['/login']);
  };

  returnToVerify(){
    this.router.navigate(['/verify_otp']);
  }

  requestResetPassword(value) {
    if (!value) { return }
    this.forgotService.requestResetPassword(value).subscribe(
      (response) => {
    
        if(response['success']){
          this.showMailConfirmation = true;
          this.alert.showAlertMessage(response);
        }
        
      },
      error => {
        console.log("forgot password",error);
        this.alert.showAlertMessage(error.error);
      }
    )
  };

}

