import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login',
    loadChildren:()=>import('./components/login/login.module').then(m=>m.LoginModule)  
   },
  {
    path: 'sign_up',
    loadChildren:()=>import('./components/signup/signup.module').then(m=>m.SignupModule)  
  },
  {
    path: 'verify_otp',
    loadChildren:()=>import('./components/verification/verification.module').then(m=>m.VerificationModule)  
  },
  {
    path: 'password_reset',
    loadChildren:()=>import('./components/password-reset/passwod-reset.module').then(m=>m.PasswordResetModule)  
  },{
    path:'forgot_password',
    loadChildren:()=>import('./components/forgot-password/forgot.password.module').then(m=>m.ForgotModule)
  }
//   {
//     path:'main',
//     loadChildren:()=>import('../app/views/layout/layout.module').then(m=>m.LayoutModule)
//    }
  // {
  //     path: 'connections', component: ConnectionsComponent, children: [
  //         { path: ':id', component: ConnectionDetailComponent }]
  // }
];

@NgModule({
    imports: [
      CommonModule,
      RouterModule.forRoot(routes,{ useHash: true })
    ],
    declarations: [],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }