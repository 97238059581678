import { DOCUMENT } from '@angular/common';
import { AfterContentChecked, AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, Output, ViewChild, Directive, Inject  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
import { AppComponent } from 'app/app.component';
import { AlertPopup, config, HttpLoader, path } from 'app/app.config';
import { ChatService } from 'app/components/chat-list/chat.service';
import { StaffRoomService } from 'app/components/staff-room/staff-room.service';
import { GroupService } from 'app/components/userandgroup/group/group.service';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { SocketService } from 'app/socket/socket.service';
import { Subject } from 'rxjs';
import * as _ from 'underscore';

import { ELibraryService } from './e-library.service';
import { AssistBotService } from 'app/components/ai-assist/assist-bot.service';
// import { ELibraryUploadComponent } from './e-library-details/e-library-upload/e-library-upload.component';

//@Directive()
@Component({
  selector: 'app-e-library',
  templateUrl: './e-library.component.html',
  styleUrls: ['./e-library.component.scss'],
})
export class ELibraryComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @HostBinding('class.flex-grid') leftBarClass = true;
 @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('elibraryRecordList', {static: true}) private elibraryScrollContainer: ElementRef;
  @Input() activeLinkType: ElementRef;
  @Output() activeType: string;
  profileUrl = path.PROFILE_URL;
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  activeUrl: any;

  viewControl = {
    changedSearchText : false,
    closeEmptyMessages : false
  }

  sortBy = 'title';
  showSearchSec = true;
  totalRowCount :number;
  totalLessionsCount: number;
  totalUserCount: number;
  search: string;
  searchInList = new Subject<any>();
  viewLibraryList = false;
  showEmptyState = true;
  showNotification = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  showPaginateSpinner = false;
  listSortBy = 'updatedAt';
  getTotalLesson:number;
  getTotalUser: number;
  listOfGroups= [];
  isUserRole: number;
  welcomeWindowName: string;
  showWelcomeWindow = true;
  loggedUserDeatails: any;
  is_tour_completed = false;


  elibraryList:any[] = [];
  // public elibraryList = [];
  public subscribers: any = {};
  searchSubject = new Subject<any>();

  constructor(
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private socketService: SocketService,
    private dataSharing: DataSharingService,
    private chatService: ChatService,
    private staffRoomService: StaffRoomService,
    private groupService: GroupService,
    private router: Router,
    private route: ActivatedRoute,
    private appComponent: AppComponent,
    private elibraryService:ELibraryService,private assistBotService:AssistBotService,
     @Inject(DOCUMENT) private document:Document
    // private elibraryUploadComponent: ELibraryUploadComponent
  ) {
    this.searchInList
      .debounceTime(500)
      .subscribe(event => {
        this.search = event.target.value;
        if (!this.search) {
          return;
        }
        this.viewControl.changedSearchText = true;
        this.getElibrary(0);
      });

      this.searchSubject.debounceTime(500).subscribe(
        event => {
          this.viewControl.changedSearchText = true;
          const searchText = event.target.value;
          this.searchUserList(searchText);
        }
      )

      this.groupService.emitNewGroupCreation().subscribe((group) => {
        this.elibraryList.unshift(group);
      });
  }

  ngOnInit(): void {
    let client="absencegpt";
    this.welcomeWindow('welcome');
    this.getElibrary(0);

    this.route.url.subscribe(() => {
      this.activeUrl = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url[0].path : false;
      this.viewLibraryList = false;
    });

    var activeRoleId = JSON.parse(window.localStorage.getItem('user_role'));
    // var roleId = activeRoleId.replace(/\"/g, "");
    this.isUserRole = parseInt(activeRoleId);
    this.assistBotService.getClientConfig().subscribe((res:any)=>{
      this.assistBotService.GptConfiguration = res.filter((configlist:any)=> configlist['clientModule']==client)[0]['configuration'];
     
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    this.dataSharing.getLessionCount().subscribe(response => {
     this.elibraryList.filter(x =>{
        if(x.group_id === parseInt(response.group_id)){
          x.TotalLessonCount+=1
          // console.log(x);
        }
      })

    })
  }

  ngAfterContentChecked(): void {
    if (_.isEqual(this.loggedUserDeatails, this.appComponent.userDetail)) {
      return;
    }

    if (_.isObject(this.appComponent.userDetail)) {
      this.loggedUserDeatails = this.appComponent.userDetail;
      this.is_tour_completed = !this.loggedUserDeatails.is_tour_completed
    }
  }

  onScrollDown(){

    const listLength = this.elibraryList.length;

    if (this.totalRowCount > listLength && !this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
      this.getElibrary(this.OFFSET);
    }
  }

  getElibrary(pageNo){
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
        this.httpLoader.showLoader();
      }
      this.listSortBy = (this.sortBy === 'time') ? 'updatedAt' : 'full_name';
      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: this.search || '',
        sort: this.sortBy
      };

      this.staffRoomService.getClassList(payLoad).subscribe(
        response => {
          this.httpLoader.hideLoader();
          if (offset === 0) {
            this.httpLoader.hideLoader();
            if(response.hasOwnProperty('result')){
              this.elibraryList = response['result'];
            }else{
              this.elibraryList = response['chatList'];
            }
            
           if(this.elibraryList.length > 0){
            
             this.router.navigateByUrl('main/elibraryandcourse/e-library/'+this.elibraryList[0].group_id);
           }
            // this.dataSharing.emitElibaryDashboardCount(this.elibraryList);
          } else if (offset > 0) {
            this.showPaginateSpinner = false;
            if(response.hasOwnProperty('result')){
              this.elibraryList = this.elibraryList.concat(response['result']);
            }else{
              this.elibraryList = this.elibraryList.concat(response['chatList']);
            }
          
          }
          this.totalRowCount = response['TotalRowCount'];
        },
        (error) => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateSpinner = false;
          this.httpLoader.hideLoader();
        }
      )
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  }

  /** Active Teacher help Desk */
  welcomeWindow(name) {

    if (name) {
      this.showWelcomeWindow = true;
      this.welcomeWindowName = name;
    } else {
      this.showWelcomeWindow = false;
    }
  }

  showOnboarding() {
    //this.hintService.initialize({ elementsDisabled: true });
    this.showWelcomeWindow = false;
  }

  searchUserList(searchUser){
    // this.getAllChatList(searchUser);
  }

  scrollToTop(){

  }

  fetchElibraryList(){

  }

  closedEmptyClassMsg(){
    this.viewControl.closeEmptyMessages = true;
    this.document.getElementsByClassName('second-level-menu')[0].classList.add('hide');
  }



  // showSearchbox(){
  //   this.showSearchSec = !this.showSearchSec;
  // }

  showSearchBox(toggle){
    this.showSearchSec = !this.showSearchSec;
    if (this.viewControl.changedSearchText) {
      try {
        this.search = '';
        this.viewControl.changedSearchText = false;
        const offset = 0;
        this.getElibrary(offset);
      } catch (error) {
        this.httpLoader.hideLoader();
      }
    }
  }

  editLibrary(){
    this.elibraryService.elibraryBS.next({"action":"showeditlibrary"});
  }
  
  deleteLibrary(){
    
  }

}
