import { config } from './app.config';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { AuthService } from './auth-service.service';
import * as CryptoJS from 'crypto-js';
import * as _ from 'underscore';

const secretKey = config.SECRETKEY;


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, public router: Router) { }

  private modifyBody(body: any) {
    if (_.isObject(body) && body.encData) {
      const ciphertext = body.encData;
      const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
      const bytesToStr = bytes.toString(CryptoJS.enc.Utf8);
      const parsedBody = JSON.parse(bytesToStr);
      // console.log(parsedBody);
      return parsedBody;
    } else {
      return body;
    }

    // return body;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const bodyObj = req.body;
    let newReq = null;
    if (_.isObject(bodyObj) && config.HOST === config.DOMAIN_NAME && !req.reportProgress) {
      const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(bodyObj), secretKey);
      const encryptStr = { encData: ciphertext.toString() };
      newReq = req.clone({ body: encryptStr });
      newReq = newReq.clone({ setHeaders: { 'x-auth-token': token } });

    } else {
      newReq = req.clone({ setHeaders: { 'x-auth-token': token } });
    }
  //console.log("request",req.body);
    return next.handle(newReq)
    .map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({ body: this.modifyBody(event.body) });
        }
      //  console.log("response",event);
        return event;
      })
      .catch(response => {
        if (response instanceof HttpErrorResponse) {
          if (response.status === 401) {
            window.localStorage.clear();
            this.router.navigate(['/login']);
          } else if (response.status === 403) {
            this.router.navigate(['/verify_otp']);
            // this.router.navigate(['/rest_password']);
          } else if (response.status === 421) {
            this.router.navigate(['/main/chats']);
          }
        }
        return Observable.throw(response);
      });
  }


}
